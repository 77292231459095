import dataDetail from "@/utils/dataDetail";
import { isLinkGame, isGameGoLink, $get, $post } from '@/utils/request.js'
import { Toast } from "vant";
import router from "@/router";
import store from ".";
let giftConfigLoading = false;
let giftConfigReqTimes = 0;
function randPrize() {

}

function randName() {
  let name =
    String(dataDetail.rand(72, 99)) +
    dataDetail.rand(3, 9) +
    "****" +
    parseInt(Math.random() * 10) +
    parseInt(Math.random() * 10) +
    parseInt(Math.random() * 10);
  return name;
}

const systemConfig = {
  state: {
    chestNotice: [],
    oddsArr: [],
    goodsArr: [],
    giftRule: ""
  },
  mutations: {
    SET_GIFTCONFIG: (state, { odds, goods, rule }) => {
      state.oddsArr = odds;
      state.goodsArr = goods;
      state.giftRule = rule;
    },
    SET_CHESTNOTICE: (state, data) => {
      state.chestNotice = [...state.chestNotice, ...data]
    }
  },
  getters: {
    blindOdds(state) {
      if (!state.oddsArr.length) {
        store.dispatch("getGiftConfig")
      }
      return state.oddsArr;
    },
    blindGoods(state) {
      if (!state.goodsArr.length) {
        store.dispatch("getGiftConfig")
      }
      return state.goodsArr;
    },
    blindNotice(state){
      if(!state.chestNotice.length){
        store.dispatch("initChestNotice")
      }
      return state.chestNotice;
    }
  },
  actions: {
    initChestNotice({ state, commit }, payload) {
      // ;
      const odds = state.oddsArr;
      const goods = state.goodsArr;
      if(!odds.length || !goods.length) return store.dispatch("getGiftConfig")
      const res = [];
      for (let i = 0; i < 128; i++) {
        const flag = dataDetail.rand(0, 1);
        const name = randName()
        if (flag) {
          const prizeIndex = dataDetail.rand(0, odds.length - 1);
          res.push({
            name,
            prize: odds[prizeIndex].title,
            text: `Chúc mừng khách hàng ${name} trúng thưởng ${odds[prizeIndex].title}`
          })
          continue;
        }
        const goodIndex = dataDetail.rand(0, goods.length - 1);
        res.push({
          name,
          prize: goods[goodIndex].title,
          text: `Chúc mừng khách hàng ${name} trúng thưởng ${goods[goodIndex].title}`
        })
      }
      commit("SET_CHESTNOTICE", res)
    },
    getGiftConfig({ commit }) {
      if (giftConfigLoading || giftConfigReqTimes > 5) return;
      giftConfigReqTimes++;
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await $get('/marketing/gift', {
            act: 'list',
            type: 'point'
          })
          store.dispatch("verifyRes", data).then((res) => {
            const { setting: { odds = [], goods = [], rule = "" } } = res;
            commit("SET_GIFTCONFIG", { odds, goods, rule })
            store.dispatch("initChestNotice");
            return resolve(res)
          })
        } catch (error) {
          console.error('获取中奖列表错误:', error);
          giftConfigReqTimes--;
          return reject(error)
        }
      })
    },
  },
}
export default systemConfig