<template>
  <div id="app" :style="`--theme: ${verConfig.theme || '#0083e1'}`">
    <router-view />
    <!-- <Floatball @click="toChatroom" v-if="showFloatBall">
      <div class="float_ball_wrap">
        <img src="./assets/icon/float_ball_msg.png" alt="" />
      </div>
    </Floatball> -->
  </div>
</template>

<script>
import Floatball from './components/floatBall.vue'
import { $get, $post } from '@/utils/request'
import { Dialog } from 'vant'
export default {
  name: 'App',
  components: {
    Floatball
  },
  data() {
    return {
      showFloatBall: false
    }
  },
  created() {
    this.getDialog()
  },
  methods: {
    toChatroom() {
      this.$router.push('/ChatList')
    },
    async getDialog() {
      try {
        const {
          data: { ret, msg, data }
        } = await $get('/content/winnotice')
        if (!!data && !!data[0] && !!data[0].content) {
          Dialog.alert({ message: data[0].content, confirmButtonText: this.$t('common.sure') })
        }
      } catch (error) { }
    }
  },
  watch: {
    $route(to, from) {
      const token = localStorage.getItem('TOUTIAO_USER')
      if (
        to.path == '/ChatList' ||
        to.path == '/ChatRoom' ||
        !token ||
        !this.verConfig.hasChat
      ) {
        this.showFloatBall = false
      } else {
        this.showFloatBall = true
      }
    }
  }
}
window.onload = function () {
  undefined
  document.addEventListener('touchstart', function (event) {
    undefined
    if (event.touches.length > 1) {
      undefined
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function (event) {
    undefined
    event.preventDefault()
  })
}
</script>

<style lang="less">
.box {
  width: 400px;
  height: 200px;
  background-color: red;
}
</style>
<style lang="less" scoped>
.float_ball_wrap {
  @border_size: 4px;
  border: @border_size solid #758fa0;
  background: linear-gradient(0deg, #0023ff 0%, #00b4ff 100%),
    linear-gradient(#074669, #074669);
  height: calc(var(--size) - @border_size * 2); // 这个size是来自floatBall组件的
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  >img {
    width: 60%;
    height: 60%;
  }
}
</style>
