export const ver = 'A6232'
const verArr = {
  A6232: {
    moneyType: 'PESO',
    homeProfitFormat: true,
    lang: 'en',
    areaCode: '63',
    url: 'https://lsapp.site/',
    title: 'Lisboeta suncity',
    // mineLogo: require('@/assets/images/mine_icon.png'),
    // mineBG: require('@/assets/images/vi_mine_bg.png'),
    // hasChat: true,
    needMoneyNot: true,
    // chatUrl: 'https://dd5556.com',
    isLinkGame: true,
    hasDiscover: true,
    hasNotLoginOut: true
    // isThird: true
  }
}
export default verArr[ver]
