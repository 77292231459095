import Vue from 'vue'
import { Locale } from 'vant';
import VueI18n from 'vue-i18n'
import verConfig from '@/utils/ver.js'
import Cookies from 'js-cookie'
import vi from './vi.json'
import th from './th.json'
import en from './en.json';
import tl from './tl.js'
import thTh from 'vant/es/locale/lang/th-TH';
import viVN from 'vant/es/locale/lang/vi-VN';
import enUS from 'vant/es/locale/lang/en-US';
Vue.use(VueI18n)
Locale.use('th-TH', thTh);
Locale.use('vi-VN', viVN);
Locale.use('en-US', enUS);
export const messages = {
  en: {
    ...en
  },
  tl:{
    ...tl
  },
  th: {
    ...th
  }
}

export const Lans = [
  {
    name: 'English',
    value: 'en'
  },
  {
    name: 'Filipino',
    value: 'tl'
  },
  {
    name: 'แบบไทย',
    value: 'th'
  },
]

export function getLanguage() {
  const chooseLanguage = Cookies.get('language')
  if (!chooseLanguage) {
    Cookies.set('language', verConfig.lang || 'en')
    localStorage.setItem('language', verConfig.lang || 'en')
  }
  return chooseLanguage || verConfig.lang || 'en'
}
const i18n = new VueI18n({
  locale: getLanguage(),
  messages
})

export default i18n
