/**
 * 封装自定义 dayjs 日期处理模块
 */

import Vue from 'vue'
import dayjs from 'dayjs'
// 加载中文语言包
import 'dayjs/locale/zh-cn'

import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import calendar from 'dayjs/plugin/calendar'
import i18n from '@/lang'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(calendar)
// dayjs 默认语言是英文 我们这里配置为中文
// dayjs.locale('zh-cn') // 全局使用

Vue.filter('relativeTime', (value) => {
  return dayjs().to(dayjs(value))
})
Vue.filter('formatTime', (value, format = 'YYYY-MM-DD') => {
  return dayjs(value).format(format)
})
Vue.filter('formatTimeHandler', (value) => {
  if (dayjs().isSame(value, 'day')) {
    return dayjs(value).format('HH:mm')
  } else if (value < new Date()) {
    return dayjs(value).format('YYYY-MM-DD HH:mm')
  }
})
Vue.filter('calendar', (value) => {
  if (dayjs().isSame(value, 'day')) {
    const todayText = i18n.t('home.today')
    // return dayjs().calendar(value, { sameDay: '[hôm nay] HH:mm' })
    return dayjs().calendar(value, { sameDay: `[${todayText}] HH:mm` })

  } else if (value < new Date()) {
    return dayjs(value).format('YYYY-MM-DD HH:mm')
  }
})
