import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueI18n from 'vue-i18n'
import i18n, { getLanguage } from './lang'
import Vant from 'vant'
import 'vant/lib/index.css'
import './assets/fonts/fonts.css'
import 'amfe-flexible'
import './utils/day.js'
import './styles/index.less'

import VueClipboard from 'vue-clipboard2'
import { isLinkGame } from '@/utils/request'

// axios.baseURL = process.env.VUE_APP_URL;
// 
import verConfig, { ver } from './utils/ver'
import formatMoney from './utils/formatMoney'
import formatImage from './utils/imgUrlFormat'
import format from './utils/format'
import { cookie } from '@/utils/tools'

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.prototype.ver = ver
Vue.prototype.verConfig = verConfig
Vue.prototype.$money = formatMoney
Vue.prototype.$img = formatImage
Vue.prototype.lan = getLanguage()
Vue.prototype.$cookie = cookie

document.title = verConfig.title
// 动态更改网站图标
const icoLink = document.createElement('link')
icoLink.type = 'image/png'
icoLink.rel = 'icon'
const icoVer = ver
icoLink.href = require(`@/assets/ico/${icoVer}.png`)
document.head.appendChild(icoLink)
// process.env.VUE_APP_URL = verConfig.url;

Vue.prototype.$time = format
Vue.prototype.$format = format
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.prototype.$isLinkGame = isLinkGame

// 挂载vant 组件库
Vue.use(Vant)
axios.defaults.baseURL = '/api'

Vue.use(Viewer)
Viewer.setDefaults({
  Options: { inline: true, button: true, navbar: true, title: true, toolbar: true, tooltip: true, movable: true, zoomable: true, rotatable: true, scalable: true, transition: true, fullscreen: true, keyboard: true, url: 'data-source' }
})
Vue.use(VueI18n, {
  i18n: (key, value) => i18n.t(key, value)
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
